const convertFromBirthdayToAge = (birthday) => {
  // 生年月日を日付型に変換
  const year = Number(birthday.toString().substr(0, 4));
  const month = Number(birthday.toString().substr(4, 2));
  const day = Number(birthday.toString().substr(6, 2));
  const birthDate = new Date(year, month - 1, day);

  // 現在の日付を取得
  const today = new Date();

  // 年齢を計算
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export default convertFromBirthdayToAge;
