import firebase from "firebase/app";
const db = firebase.firestore();
export const deleteFirebaseUser = async (userUid, deletedClientName, deletedClientId) => {
  await db
    .collection("users")
    .doc(userUid)
    .set(
      {
        inviteCode: "temporary",
        deletedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        deletedClientName,
        deletedClientId,
      },
      { merge: true }
    )
    .then(() => {
      console.log("success");
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
      setErrorLog(deletedClientId);
    });
};

export const setErrorLog = (uid, errorCode, errorMessage, errorMethod) => {
  db.collection("eatasManagementErrors")
    .doc(uid)
    .collection("error")
    .set({
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      errorCode,
      errorMessage,
      errorMethod,
    })
    .then(() => {
      console.log("成功");
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};

export const setClientSettings = async (clientId) => {
  const clientSettingDocs = await db.collection("clients").doc(clientId).collection("clientSettings").get();
  const clientSetting = clientSettingDocs.docs;
  return clientSetting;
};

export const setClientUserSettings = async () => {
  // ログイン者情報を取りにいく
  await firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      const uid = user.uid;
      const authUser = user;
      await this.$store.dispatch("serClientUserData", uid);
    }
  });
  // storeにセットする
};
