<template>
  <div>
    <CRow>
      <CCol class="client-create">
        <CCard class="">
          <CCardBody class="">
            <h1>ユーザー新規登録（管理用）</h1>
            <h2>基本情報入力</h2>
            <div class="input-box">
              <div class="small-input">
                <div>名前：性</div>
                <input v-model="lastName" class="" type="text" name="name" />
              </div>
              <div class="small-input">
                <div>名</div>
                <input v-model="firstName" class="" type="text" />
              </div>
            </div>
            <div class="input-box">
              <div class="small-input">
                <div>ふりがな：せい</div>
                <input v-model="lastNameRuby" class="" type="text" />
              </div>
              <div class="small-input">
                <div>めい</div>
                <input v-model="firstNameRuby" class="" type="text" />
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div>性別</div>
                <input v-model="sex" name="sex" id="male" type="radio" value="男性" />
                <label for="male">男性</label>
                <input v-model="sex" name="sex" id="female" type="radio" value="女性" />
                <label for="female">女性</label>
                <input v-model="sex" name="sex" id="queer" type="radio" value="どちらでもない" />
                <label for="queer">どちらでもない</label>
              </div>
            </div>
            <div class="input-box">
              <div class="small-input">
                <div>生年月日</div>
                <input v-model="birthday" class="" type="text" />
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div>身長</div>
                <input v-model="height" class="middle-input" type="text" name="" />
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div>体重</div>
                <input v-model="weight" class="middle-input" type="text" name="" />
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div>郵便番号</div>
                <input v-model="postalCode" class="middle-input" type="text" name="" />
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div>都道府県</div>
                <input v-model="address1" class="middle-input" type="text" name="" />
              </div>
            </div>
            <div class="input-box">
              <div class="">
                <div>市区町村</div>
                <input v-model="address2" class="middle-input" type="text" name="" />
              </div>
            </div>
            <button class="green btn" @click="addUser">新規登録</button>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();
export default {
  name: "UserRegistration",
  data() {
    return {
      id: "",
      lastName: "",
      firstName: "",
      lastNameRuby: "",
      firstNameRuby: "",
      sex: "",
      birthday: "",
      height: "",
      weight: "",
      postalCode: "",
      address1: "",
      address2: "",
      options: [
        { id: 1, name: "スクールママ" },
        { id: 2, name: "アフタヌーン" },
      ],
      optionsPurpose: [
        { id: 1, name: "ダイエット" },
        { id: 2, name: "引き締め" },
      ],
    };
  },
  computed: {},
  methods: {
    async addUser() {
      const userDocRef = await this.$store.dispatch("createUserDocRef");
      // console.log(userDocRef.id);
      await db
        .collection("users")
        .doc(userDocRef.id)
        .set({
          id: userDocRef.id,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          lastName: this.lastName,
          firstName: this.firstName,
          lastNameRuby: this.lastNameRuby,
          firstNameRuby: this.firstNameRuby,
          sex: this.sex,
          birthday: this.birthday,
          height: this.height,
          weight: this.weight,
          postalCode: this.postalCode,
          address1: this.address1,
          address2: this.address2,
        });
      this.$store.commit("closeUserRegistrationModal");
    },
  },
};
</script>
<style scoped>
.name {
  display: flex;
}

input {
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #768192;
}

input[type="radio"] {
  margin: 5px;
}

label {
  margin-right: 2rem;
  margin-bottom: 0px;
}

.small-input {
  margin-right: 1rem;
}

.input-box {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
}

.middle-input {
  width: 300px;
}
.course select {
  width: 300px;
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #3c4b64;
}
</style>
