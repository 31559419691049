<template>
  <div class="fullview">
    <div class="cssload-container">
      <div class="cssload-loading"><i></i><i></i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style>
.fullview {
  width: 100vw;
  height: 100vh;
  background: #fefefe;
  position: absolute;
  top: 0;
  left: 0;
}
.cssload-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  /* width: 56px; */
}

.cssload-loading {
  width: 51px;
  height: 51px;
  text-align: left;
  animation: cssload-loading-ani1 1.9s ease-in-out infinite;
  -o-animation: cssload-loading-ani1 1.9s ease-in-out infinite;
  -ms-animation: cssload-loading-ani1 1.9s ease-in-out infinite;
  -webkit-animation: cssload-loading-ani1 1.9s ease-in-out infinite;
  -moz-animation: cssload-loading-ani1 1.9s ease-in-out infinite;
}
.cssload-loading i,
.cssload-loading i:before {
  content: "";
  width: 51px;
  height: 51px;
  position: absolute;
}
.cssload-loading i {
  border-radius: 50%;
  overflow: hidden;
}
.cssload-loading i:nth-child(1) {
  background: rgba(255, 134, 107, 0.97);
  transform: translate(0, -28px);
  -o-transform: translate(0, -28px);
  -ms-transform: translate(0, -28px);
  -webkit-transform: translate(0, -28px);
  -moz-transform: translate(0, -28px);
}
.cssload-loading i:nth-child(1):before {
  background: rgb(252, 184, 101);
  bottom: 0;
  animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -o-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -ms-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -webkit-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -moz-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
}
.cssload-loading i:nth-child(2) {
  background: rgb(252, 184, 101);
  transform: translate(0, 28px);
  -o-transform: translate(0, 28px);
  -ms-transform: translate(0, 28px);
  -webkit-transform: translate(0, 28px);
  -moz-transform: translate(0, 28px);
}
.cssload-loading i:nth-child(2):before {
  animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -o-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -ms-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -webkit-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  -moz-animation: cssload-loading-ani2 1.9s ease-in-out infinite;
  background: rgb(255, 134, 107);
}

@keyframes cssload-loading-ani1 {
  40%,
  50% {
    transform: rotate(180deg);
  }
  90%,
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-loading-ani1 {
  40%,
  50% {
    -o-transform: rotate(180deg);
  }
  90%,
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-loading-ani1 {
  40%,
  50% {
    -ms-transform: rotate(180deg);
  }
  90%,
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-loading-ani1 {
  40%,
  50% {
    -webkit-transform: rotate(180deg);
  }
  90%,
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-loading-ani1 {
  40%,
  50% {
    -moz-transform: rotate(180deg);
  }
  90%,
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes cssload-loading-ani2 {
  40%,
  100% {
    height: 100%;
  }
  50%,
  90% {
    height: 0;
  }
}

@-o-keyframes cssload-loading-ani2 {
  40%,
  100% {
    height: 100%;
  }
  50%,
  90% {
    height: 0;
  }
}

@-ms-keyframes cssload-loading-ani2 {
  40%,
  100% {
    height: 100%;
  }
  50%,
  90% {
    height: 0;
  }
}

@-webkit-keyframes cssload-loading-ani2 {
  40%,
  100% {
    height: 100%;
  }
  50%,
  90% {
    height: 0;
  }
}

@-moz-keyframes cssload-loading-ani2 {
  40%,
  100% {
    height: 100%;
  }
  50%,
  90% {
    height: 0;
  }
}
</style>
